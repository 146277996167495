'use strict';

(function () {
  class ListRepairCtrl {
    constructor($scope, $rootScope, $state, $uibModal, Cart, usSpinnerService, Repair, Auth, Util, Modal, toastr, ActivityLog, SharedUrl, $sce) {
      this.$scope = $scope;
      this.$rootScope = $rootScope;
      this.$uibModal = $uibModal;
      this.Cart = Cart;
      this.Auth = Auth;
      this.isAdmin = Auth.isAdmin;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.$state = $state;
      this.Modal = Modal;
      this.toastr = toastr;
      this.turbine = $scope.turbine;
      this.blade = $scope.blade;
      this.usSpinnerService = usSpinnerService;
      this.Repair = Repair;
      this.allRepairs = [];
      this.repairs = [];
      this.filteredRepairs = [];
      // used for print group by blade
      // this.components = [];
      //this.selectedCategory = 'all';
      this.colXsPrintNumber = 2;

      this.bladePositionMap = {
        'A': 1,
        'B': 2,
        'C': 3
      };
      this.ActivityLog = ActivityLog;
      this.SharedUrl = SharedUrl;
      this.$sce = $sce;
      this.showRepairPopover = [];
      this.sharedLevel = localStorage.getItem('sharedLevel');
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });

      //Refresh Repairs on event
      $scope.$on('bladeComponent', (event, args) => {
        if (args.refresh && this.turbine) {
          this.refreshRepairs();
        }
      });

      $scope.$watch('daterange', (newVal, oldVal) => {
        this.filterRepairs();
      });

      //showAllRepairImages
      // $rootScope.$on('showAllRepairImages', (event, args)=> {
      //   this.showAllRepairImages();
      // });

      this.refreshRepairs();
    }

    filterRepairs() {
      if (this.$scope.daterange && this.$scope.daterange.startDate && this.$scope.daterange.endDate) {
        this.filteredRepairs = _.filter(this.repairs, (repair) => {
          return moment(repair.date || repair.createdAt).isBetween(this.$scope.daterange.startDate, this.$scope.daterange.endDate);
        });
      }
      else {
        this.filteredRepairs = this.repairs;
      }

      this.repairComponents = ListRepairCtrl.getBladesGroup(this.filteredRepairs);
    }

    /* filterResults() {
      if (this.selectedCategory != 'all') {
        this.repairs = this.allRepairs.filter(repair => repair.category >= this.selectedCategory);
      } else {
        this.repairs = this.allRepairs.slice();
      }
    } */

    showAllRepairImages() {
      _.each(this.repairs, (repair) => {
        repair.open = true;
      })
    }

    hideAllRepairImages() {
      _.each(this.repairs, (repair) => {
        repair.open = false;
      })
    }

    showHideAllRepairBtn() {
      return _.find(this.repairs, { open: true });
    }

    getBladePosition(position) {
      // console.log('get blade pos: ', position);
      return this.bladePositionMap[position] ? this.bladePositionMap[position] : position;
    }

    refreshRepairs() {
      this.fetchRepairs();
    }

    fetchRepairs() {
      this.loading = true;
      let qry = { id: this.turbine._id };
      if (this.blade) {
        qry.controller = this.blade._id;
      }
      this.Repair.get(qry).$promise
        .then(response => {
          if (response.data) {
            this.allRepairs = response.data;
            this.repairs = response.data;
            this.filteredRepairs = response.data;
            // console.log('repairs: ', this.repairs);
            // this.repairComponents = ListRepairCtrl.getBladesGroup(this.allRepairs);
            this.$rootScope.$broadcast('repairsReceived', response.data);
            // console.log('repair components: ', this.repairComponents);

            this.hideAllRepairImages();
          }
          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err => {
          console.log(err);
          this.stopSpin('spinner-1');
          this.loading = false;
        })
    }

    static getBladesGroup(allRepairs) {
      let repairs = allRepairs.sort((a, b) => a.blade.position - b.blade.position);
      let allBlades = [];
      let blades = {};
      for (let i = 0; i < repairs.length; ++i) {
        let obj = repairs[i];
        if (blades[obj.blade._id] === undefined)
          blades[obj.blade._id] = [];
        blades[obj.blade._id].push(obj);
      }
      for (let key in blades) {
        allBlades.push({ repairs: blades[key], blade: blades[key][0].blade });
      }
      return allBlades;
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    deleteRepair(repair) {
      this.repairDeleteError = null;
      this.Modal.confirm.delete((repair) => {
        // console.log('delete', repair);
        this.Repair.delete({ id: repair._id }).$promise
          .then(() => {
            console.log('repair deleted');
            _.remove(this.repairs, { _id: repair._id });
            this.bladeStr = '';
            if(repair.blade.make) {
              this.bladeStr+=repair.blade.make+' ';
            }
            if(repair.blade.model) {
              this.bladeStr+=repair.blade.model;
            }
            if(repair.blade.name) {
              this.bladeStr+=repair.blade.name;
            }

            this.turbineStr = '';
            if(repair.turbine.make) {
              this.turbineStr+=repair.turbine.make;
            }
            if(repair.turbine.model) {
              this.turbineStr+=repair.turbine.model;
            }
            if(repair.turbine.name) {
              this.turbineStr+=repair.turbine.name;
            }

            let uniqueReferenceNumber ='NA';
            if(repair.finding && repair.finding.uniqueReferenceNumber) {
              uniqueReferenceNumber = `<a href="/blade/${repair.blade._id}/finding/${repair.finding._id}">${repair.finding.uniqueReferenceNumber}</a>`;
            }
            
            let activityLogData = {
              site: this.turbine.site._id,
              turbine: repair.turbine._id,
              blade: repair.blade._id,
              repair: repair._id,
              user: this.currentUser._id,
              source: 'web',
              action: 'delete',
              type: 'repair',
              sourceScreen: 'blade',
              eventString: `Repair work order <a href="/repair/${repair._id}">${repair.woId}</a> was deleted for ${uniqueReferenceNumber} in wind farm <a href="/site/${this.turbine.site._id}">${this.turbine.site.name}</a>, turbine <a href="/turbine/${repair.turbine._id}">${this.turbineStr}</a>, blade <a href="/blade/${repair.blade._id}">${this.bladeStr}</a> by ${this.currentUser.name}`
            }

            if (repair.finding) {
              activityLogData.finding= repair.finding._id;
            }
      
            this.ActivityLog.save(activityLogData).$promise
              .then(activityLog => {
                console.log(activityLog);
              })
              .catch(err => {
                console.log('Error inserting activityLog', err);
            });
            this.$state.reload();
          })
          .catch(err => {
            console.log('err', err);
            this.repairDeleteError = err;
            this.toastr.error('repair not deleted. please try again or contact administrator.');
          })
      })('repair with id: ' + repair._id, repair);
    }

    editRepair(repair) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/repair/manage-repair-modal.html',
        controller: 'ManageRepairModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Edit Repair',
            repair: repair,
            boxW: 400,
            boxH: 400,
            lens: false
          }
        }
      });
      modalInstance.result.then(result => {
        this.refreshRepairs();
      });
    }

    moveRepair(repair) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/repair/move-repair-modal.html',
        controller: 'MoveRepairModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Move Repair',
            repair: repair,
            site: this.turbine.site
          }
        }
      });
      modalInstance.result.then(result => {
        this.fetchRepairs();
      });
    }

    openFullImage(url) {
      if(!url) {
        return;
      }
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-full-image/viewFullImageModal.html',
        controller: 'ViewFullImageModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          imageUrl: function () { return url }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    shareRepairUrl(repair, index) {
      const shareUrlData = {
        url: `repair/${repair._id}`,
        level: 'repair',
        organization: this.currentUser.organization._id,
      }
      var url = window.location.href;
      var urlArray = url.split("/");
      var baseUrl = urlArray[0] + "//" + urlArray[2];
      this.SharedUrl.save(shareUrlData).$promise
        .then(sharedUrlResponse => {
          this.urlShared = `${baseUrl}/shared/${sharedUrlResponse.data._id}`;
          this.htmlPopover = this.$sce.trustAsHtml(`${this.urlShared} <i>Users without an account will see a read-only version of this page.</i>`);
          this.showRepairPopover[index] = true;
          var dummyElement = document.createElement("textarea");
          document.body.appendChild(dummyElement);
          dummyElement.value = this.urlShared;
          dummyElement.select();
          document.execCommand("copy");
          document.body.removeChild(dummyElement);
          this.toastr.success('Link copied successfully.');
        })
        .catch(err => {
          console.log('Error inserting sharedUrl', err);
      });
    }

    /* resolve(repair) {
      this.Cart.addRepair({ controller: this.Auth.getCurrentUser().organization._id }, { repairId: repair._id }).$promise
        .then(response => {
          this.toastr.success('Repair added to cart');
          this.$rootScope.$broadcast('updateCart', response.data.repairs.length);
        })
        .catch(err => {
          if (err.status === 409) {
            this.toastr.error(err.data.meta.error_message);
          } else {
            this.toastr.error('Something went wrong. please try again later.');
          }
        })
      // this.Cart.addRepair(repair);
    } */
  }

  angular.module('windmanagerApp')
    .controller('ListRepairCtrl', ListRepairCtrl);
})();
